<template>
  <div class="policy-box">
    <h1 class="font-bold text-4xl mb-2.5">{{ type === "policy" ? "Privacy Policy" : "Terms of Use" }}</h1>
    <p class="font-light mb-2.5">
      This is our {{ type === "policy" ? "Privacy Policy" : "Terms of Use" }}. Click here for our
      <a :href="termsUrl">{{ type === "policy" ? "Terms of Use" : "Privacy Policy" }}</a>.
    </p>
    <p class="font-light mb-2.5" v-if="lastUpdatedAt">Date of Last Update: <span>{{ lastUpdatedAt }}</span></p>
    <p class="text-sm opacity-70 leading-6 mb-5 tracking-wide" v-if="content" v-html="content"/>
    <p class="text-sm opacity-70 leading-6 mb-5 tracking-wide" v-if="footer"><b v-html="footer"/></p>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "policy",
    },
    url: {
      type: String,
      default: "#",
    },
    lastUpdatedAt: {
      type: String,
      default: "August 27, 2018",
    },
    content: {
      type: String,
      default: ""
    },
    footer: {
      type: String,
      default: ""
    },
  }
};
</script>

<style>
a {
  color: #29baac;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

.policy-box {
  border-bottom: 1px solid transparent;
}
</style>
