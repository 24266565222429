<template>
	<Template
			@goHome="() => $router.push('/')"
			v-bind="{
				...params,
			}"
	/>
</template>
<script>
import Template from '../components/templates/PrivacyPolicy/PrivacyPolicy';
import {c} from '@/components/constants.js'

export default {
	components: {
		Template,
	},
	data() {
		return {
			params: c.privacy,
		}
	},
}
</script>
